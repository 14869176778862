<template>
  <div id='major-loader' class='modal'>
    <div class='modal-background'></div>
    <div class='modal-content'>
      <div class='loader-wrapper'>
        <div class='scene'>
          <div class='cube'>
            <div class='cube__face cube__face--bottom'><img :src='bottomImage'></div>
            <div class='cube__face cube__face--front'><img :src='frontImage'></div>
            <div class='cube__face cube__face--right'><img :src='rightImage'></div>
            <div class='cube__face cube__face--back'><img :src='backImage'></div>
            <div class='cube__face cube__face--left'><img :src='leftImage'></div>
            <div class='cube__face cube__face--top'><img :src='topImage'></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  computed: {
    backImage: function () {
      return require('../images/A-logo-red.png');
    },
    bottomImage: function () {
      return require('../images/logo.png');
    },
    frontImage: function () {
      return require('../images/E-logo-red.png');
    },
    leftImage: function () {
      return require('../images/P-logo-red.png');
    },
    rightImage: function () {
      return require('../images/P-logo-red.png');
    },
    topImage: function () {
      return require('../images/R-reverse-logo.png');
    }
  }
};
</script>

<style>
* { box-sizing: border-box; }

.scene {
  width: 100px;
  height: 100px;
  margin: 0 auto; /*for horizontal centering*/
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: visible;
  perspective: 400px;
}

.cube {
  width: 100px;
  height: 100px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-100px);
  transition: transform 1s;
  animation: palindrome-block 25s ease infinite;
}

@keyframes palindrome-block {
  0% { transform: translateZ(-100px) rotateX(  90deg); } /* Bottom */
  2% { transform: translateZ(-100px) rotateX(  90deg); } /* Bottom */
  10% { transform: translateZ(-100px) rotateY(   0deg); } /* Front */
  18% { transform: translateZ(-100px) rotateY( -90deg); } /* Right */
  26% { transform: translateZ(-100px) rotateY(-180deg); } /* Back */
  34% { transform: translateZ(-100px) rotateY(-270deg); } /* Left */
  42% { transform: translateZ(-100px) rotateY(-360deg); } /* Front Again */
  50% { transform: translateZ(-100px) rotateX( -90deg); } /* Top */
  52% { transform: translateZ(-100px) rotateX( -90deg); } /* Top */
  /* And back again, like a palindrome... */
  60% { transform: translateZ(-100px) rotateY(   0deg); } /* Front */
  68% { transform: translateZ(-100px) rotateY(  90deg); } /* Left */
  76% { transform: translateZ(-100px) rotateY( 180deg); } /* Back */
  84% { transform: translateZ(-100px) rotateY( 270deg); } /* Right */
  92% { transform: translateZ(-100px) rotateY( 360deg); } /* Front Again */
  100% { transform: translateZ(-100px) rotateX(  90deg); } /* Bottom */
}

.cube__face {
  background-color: white;
  position: absolute;
  width: 100px;
  height: 100px;
}

.cube__face--front  { transform: rotateY(  0deg) translateZ(50px); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(50px); }
.cube__face--back   { transform: rotateY(180deg) translateZ(50px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(50px); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(50px); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(50px); }
</style>
