<template>
  <div id='book-form'>
    <form>
      <Errors :errors='errors'></Errors>
      <div class='field'>
        <label class='label'>Title</label>
        <div class='control'>
          <input class='input' type='text' name='book[title]' ref='title' v-on:blur='jankyReset'
                 v-model.trim='bookAttributes.title'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Author</label>
        <div class='control'>
          <input class='input' type='text' name='book[author]'
                 v-model.trim='bookAttributes.author'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Publication Year</label>
        <div class='control'>
          <input class='input' type='text' name='book[publication_year]'
                 v-model.trim='bookAttributes.publication_year'>
        </div>
      </div>
      <div class='field'>
        <label class='checkbox' for='public-domain-checkbox'>
          <input id='public-domain-checkbox' class='checkbox' type='checkbox'
                 name='book[public_domain]'
                 v-model.trim='bookAttributes.public_domain'>
          Public Domain?
        </label>
      </div>
      <div class='field'>
        <label class='label'>Status</label>
        <div class='control'>
          <div class='select'>
            <select name='book[status]' v-model='bookAttributes.status'>
              <option value='potential'>Potential</option>
              <option value='processing'>Processing</option>
              <option value='repaperable'>Repaperable</option>
            </select>
          </div>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Blurb</label>
        <textarea class='textarea' type='text-area' rows='3' name='book[blurb]'
                 v-model.trim='bookAttributes.blurb'></textarea>
      </div>
      <div class='field is-grouped'>
        <div class='control'>
          <button class='button is-link' v-on:click='createOrUpdateBook'>Submit</button>
        </div>
        <div v-if='book.id === null' class='control'>
          <button class='button is-text' v-on:click='triggerHideForm'>Cancel</button>
        </div>
      </div>
      <Success v-if='saved'></Success>
    </form>
  </div>
</template>

<script>
import api from '../api';
import Errors from '../components/Errors.vue'
import Success from '../components/Success.vue'

export default {
  components: { Errors, Success },
  props: ['book'],
  data() {
    return {
      errors: {},
      bookAttributes: {
        author: this.book.author,
        blurb: this.book.blurb,
        publication_year: this.book.publication_year,
        public_domain: this.book.public_domain,
        status: this.book.status,
        title: this.book.title,
      },
      saved: false
    }
  },
  methods: {
    createBook: function () {
      api
        .createBook(this.bookAttributes)
        .then(response => {
          this.onSuccess(response, 'add-book')
        })
        .catch(error => {
          this.onErrors(error)
        });
    },
    createOrUpdateBook: function (e) {
      e.preventDefault();
      this.book.id == null ? this.createBook() : this.updateBook();
    },
    jankyReset: function () {
      this.saved = false
    },
    onErrors: function (error) {
      this.errors = error.response.data.errors;
    },
    onSuccess: function (response, functionName) {
      this.$emit(functionName, response.data)
      this.errors = {};
      this.saved = true;
      this.setFocus();
    },
    setFocus: function () {
      this.$refs.title.focus();
    },
    triggerHideForm: function (e) {
      e.preventDefault();
      this.$emit('hide-form');
    },
    updateBook: function (e) {
      api
        .updateBook(this.book.id, this.bookAttributes)
        .then(response => {
          this.onSuccess(response, 'update-book')
        })
        .catch(error => {
          this.onErrors(error)
        });
    },
  },
  mounted() {
    this.setFocus();
  }
};
</script>
