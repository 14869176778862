<template>
  <div id='wishlist-button'>
    <a v-if='isWishlisted' class='button' v-on:click='removeFromWishlist'>
      {{buttonTextUndo}}
    </a>
    <a v-else class='button' v-bind:class="{'is-primary': isPrimary}"
                             v-on:click='addToWishlist'>
      <span class='icon'><i class='fas fa-heart'></i></span>
      <span>{{buttonText}}</span>
    </a>
  </div>
</template>

<script>
import api from '../api.js'

export default {
  props: ['book', 'is-primary'],
  computed: {
    buttonText: function () {
      return this.isRepaperable ? 'Add to Wishlist' : 'Request This Book'
    },
    buttonTextUndo: function () {
      return this.isRepaperable ? 'Remove from Wishlist' : 'Remove from Requested Books'
    },
    isRepaperable: function () {
      return this.book.status === 'repaperable';
    },
    isWishlisted: function () {
      return this.book.wishlisting != null;
    }
  },
  methods: {
    addToWishlist: function (e) {
      e.preventDefault();
      api.createWishlisting(this.book.id).then(response => {
        this.book.wishlisting = response.data;
      });
    },
    removeFromWishlist: function (e) {
      e.preventDefault();
      api.destroyWishlisting(this.book.wishlisting.id).then(response => {
        this.book.wishlisting = null;
        this.$emit('refetch-books');
      })
    }
  },
};
</script>
