<template>
  <div id='book-edit' class='content'>
    <a href='/admin'>Back to Admin Dashboard</a>
    <h1 class='title is-3'> Editing {{ book.title }} </h1>
    <section>
      <book-form :book='book' v-on:update-book='updateBook'></book-form>
    </section>
    <hr>
    <section>
      <h2 class='title is-4'>Chapters</h2>
      <chapters-table :book-id='book.id'></chapters-table>
    </section>
    <hr>
    <section>
      <h2 class='title is-4'>Characters</h2>
      <characters-table :book-id='book.id'></characters-table>
    </section>
  </div>
</template>

<script>
import api from "../api";
import BookForm from '../components/BookForm.vue'
import ChaptersTable from '../components/ChaptersTable.vue'
import CharactersTable from '../components/CharactersTable.vue'

export default {
  components: { BookForm, ChaptersTable, CharactersTable },
  props: ['book'],
  methods: {
    updateBook: function (updatedBook) {
      this.book = updatedBook
    },
  }
};
</script>

<style>
#book-edit {
  padding: 2%;
}
</style>
