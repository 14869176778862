<template>
  <div id='success'>
    <p class='success'>✓ Saved</p>
  </div>
</template>

<script>
export default {
};
</script>

<style type="text/css">
.success {
  color: green;
}
</style>
