<template>
  <div id='cast-edit'>
    <h2 class='title is-4'> Editing {{ cast.user_cast.title }} </h2>
    <div class='box'>
      <div class='level'>
        <div>
          <p>1. Edit the cast below to your heart's content.</p>
          <p>2. When you're happy with it, generate your ebook.</p>
          <p>3. Read it on Repaper or download it in EPUB format.</p>
        </div>
        <div class='level-right'>
          <button class='level-item button is-primary' v-on:click='generateEbook'>
            <span class='icon'><i class='fas fa-book'></i></span>
            <span><strong>Generate Ebook</strong></span>
          </button>
        </div>
      </div>
      <p class='help'>You will not be able to edit this cast after you have generated the ebook for it, but you will be able to add casts.</p>
      <p class='help'>Please note: "they/them" characters will not have perfect noun-verb agreement. For instance, "She is reading" will become "They is reading." Read more about this <a href='https://www.repaperpress.com/articles/making-non-binary-characters-with-they-them'>here</a>.</p>
    </div>
    <div class='box'>
      <Errors :errors='errors'></Errors>
      <form id='edit_user_cast'>
        <div class='field'>
          <label class='label'>Title</label>
          <input class='input' type='text' name='user_cast[title]' ref='user_cast_title'
                 v-on:blur='jankyReset'
                 v-model.trim='userCastAttributes.title'>
        </div>
        <div class='field'>
          <label class='label'>Description</label>
          <textarea class='textarea' type='text-area' rows='2'
                    name="user_cast[description]"
                    v-model.trim="userCastAttributes.description"></textarea>
          <p class='help'>This is to remind yourself how you configured this cast, and will be displayed on your review if you write one.</p>
        </div>
        <div class='field'>
          <label class='label'>Dedication</label>
          <textarea class='textarea' type="text-area" rows='2'
                  name="user_cast[dedication]"
                  v-model.trim="userCastAttributes.dedication"></textarea>
          <p class='help'>This will be inscribed in your ebook for this cast.</p>
        </div>
        <div class='control'>
          <button class='button is-link' type='submit' v-on:click='updateUserCast'>Update</button>
        </div>
        <Success v-if='userCastSaved'></Success>
      </form>
    </div>
    <hr>
    <div class='columns'>
      <div class='column'>
        <button class='button level-item' v-on:click='bulkShuffleCast'>
          <span class='icon'><i class='fas fa-random'></i></span>
          <span>Shuffle</span>
        </button>
      </div>
      <div class='column'>
        <button class='button' v-on:click='bulkFlipCast'>
          <span class='icon'><i class='fas fa-arrows-alt-h'></i></span>
          <span>Flip Original</span>
        </button>
      </div>
      <div class='column level'>
        <form class='level-right'>
          <div class='select'>
            <select v-model='genderToHomogenize'>
              <option></option>
              <option value='female'>Feminine 'She'</option>
              <option value='male'>Masculine 'He'</option>
              <option value='neutral_they'>Neutral 'They'</option>
              <option value='neutral_it'>Neutral 'It'</option>
              <option value='neutral_ne'>Neutral 'Ne'</option>
              <option value='neutral_one'>Neutral 'One'</option>
              <option value='neutral_ze'>Neutral 'Ze'</option>
            </select>
          </div>
          <button class='button' v-on:click='bulkOneGenderCast'>
            <span class='icon'><i class='fas fa-compress-arrows-alt'></i></span>
            <span>All One</span>
          </button>
        </form>
      </div>
      <div class='column level'>
        <form class='level-right'>
          <div class='select'>
            <select v-model='castToCopyId'>
              <option></option>
              <option v-for='cast in casts' v-bind:key='cast.id'
                      v-if='cast.editable != true'
                      v-bind:value='cast.id'>{{cast.user_cast.title}}</option>
            </select>
          </div>
          <button class='button' v-on:click='bulkCopyCast'>
            <span class='icon'><i class='fas fa-clone'></i></span>
            <span>Copy</span>
          </button>
        </form>
      </div>
    </div>
    <div class='columns'>
      <div class='column has-text-centered'>
        <doughnut-chart :stats='characterGenderStats'
                        :title-is-characters='true'
                        :height='200'></doughnut-chart>
      </div>
      <div class='column has-text-centered'>
        <doughnut-chart :stats='wordGenderStats'
                        :title-is-characters='false'
                        :height='200'></doughnut-chart>
      </div>
    </div>
    <div v-for='character in characters' v-bind:key='character.id'>
      <cast-character-edit :character='character' :castId='cast.id'
                           v-on:update-stats='fetchCastStats'></cast-character-edit>
    </div>
  </div>
</template>

<script>
import api from "../api.js";
import CastCharacterEdit from "../components/CastCharacterEdit.vue";
import DoughnutChart from "../components/DoughnutChart.vue";
import Errors from "../components/Errors.vue";
import Success from "../components/Success.vue";

export default {
  components: { CastCharacterEdit, DoughnutChart, Errors, Success },
  props: ['cast', 'casts'],
  data () {
    return {
      castToCopyId: '',
      characterGenderStats: this.cast.character_gender_stats,
      characters: [],
      errors: {},
      genderToHomogenize: '',
      isLoading: true,
      userCastAttributes: {
        id: this.cast.user_cast.id,
        dedication: this.cast.user_cast.dedication,
        description: this.cast.user_cast.description,
        title: this.cast.user_cast.title,
      },
      userCastSaved: false,
      wordGenderStats: this.cast.word_gender_stats
    }
  },
  methods: {
    bulkCopyCast: function (e) {
      e.preventDefault();
      var castToCopyParams = { cast_to_copy: this.castToCopyId };
      api.bulkUpdateCast(this.cast.id, 'copy', castToCopyParams).then(response => {
        this.fetchCharacters();
        this.fetchCastStats();
        this.castToCopyId = '';
      });
    },
    bulkFlipCast: function () {
      api.bulkUpdateCast(this.cast.id, 'flip').then(response => {
        this.fetchCharacters();
        this.fetchCastStats();
      });
    },
    bulkOneGenderCast: function (e) {
      e.preventDefault();
      var genderToHomogenizeParams = { gender_to_homogenize: this.genderToHomogenize };
      api.bulkUpdateCast(this.cast.id, 'homogenize', genderToHomogenizeParams).then(response => {
        this.fetchCharacters();
        this.fetchCastStats();
        this.genderToHomogenize = '';
      });
    },
    bulkShuffleCast: function () {
      api.bulkUpdateCast(this.cast.id, 'shuffle').then(response => {
        this.fetchCharacters();
        this.fetchCastStats();
      });
    },
    fetchCastStats: function () {
      api.getCastStatistics(this.cast.id).then(response => {
        this.characterGenderStats = response.data.character_gender_stats;
        this.wordGenderStats = response.data.word_gender_stats;
      });
    },
    fetchCharacters: function () {
      api.getCastCharacters(this.cast.id).then(response => {
        this.characters = response.data;
        this.isLoading = false;
      });
    },
    generateEbook: function () {
      let castWarning = "Are you sure you're happy with your cast? You will not be able to change it once its ebook has been created, but you will be able to make new casts."
      if (confirm(castWarning)) {
        this.$emit('now-loading');
        api.generateEbook(this.cast.id)
          .then(response => {
            this.$emit('refresh-casts')})
          .catch(error => {
            this.$emit('stop-loading')
            this.errors = error.response.data.errors;
          });
      }
    },
    jankyReset: function () {
      this.userCastSaved = false
    },
    updateUserCast: function (e) {
      e.preventDefault();
      api.updateUserCast(this.cast.user_cast.id, this.userCastAttributes)
        .then(response => {
          this.cast.user_cast = response.data;
          this.userCastSaved = true;
          this.$refs.user_cast_title.focus();
        }).catch(error => {
          this.errors = error.response.data.errors;
        });
    },
  },
  watch: {
    cast: function () {
      this.fetchCharacters();
    }
  },
  created () {
    this.fetchCharacters();
  },
};
</script>
