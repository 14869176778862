<template>
  <div id='articles-table'>
    <a class='button is-link' href='/articles/new'>+ New Article</a>
    <br>
    <br>
    <table class='table is-bordered is-striped is-hoverable is-fullwidth'>
      <tbody>
        <tr>
          <th>ID</th>
          <th></th>
          <th>Title</th>
          <th>Byline</th>
          <th>Created</th>
          <th>Published</th>
        </tr>
        <tr v-for='article in articles' v-bind:key='article.id'>
          <td>{{article.id}}</td>
          <td><a :href='articleEditUrl(article)'>Edit</a></td>
          <td>{{article.title}}</td>
          <td>{{article.byline}}</td>
          <td>{{article.created_at}}</td>
          <td>{{article.published}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from '../api';

export default {
  data() {
    return {
      articles: [],
    }
  },
  methods: {
    articleEditUrl: function (article) {
      return '/articles/' + article.slug + '/edit'
    },
  },
  created() {
    api.getArticles().then(response => {
      this.articles = response.data;
    });
  },
};
</script>
