<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['stats', 'title-is-characters'],
  computed: {
    chartTitle: function () {
      return this.titleIsCharacters === true ? 'Gender by # Characters' : 'Gender by # Word References';
    }
  },
  methods: {
    renderDoughnutChart: function () {
      var options = {
        legend: {
          display: false
         },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          position: 'bottom',
          text: this.chartTitle
        },
      };
      this.renderChart({
        labels: ["Feminine 'She'", "Masculine 'He'", "Neutral 'They'", "Neutral 'It'", "Neutral 'One'", "Neutral 'Ne'", "Neutral 'Ze'"],
        datasets: [
          {
            backgroundColor: ["#FFCE56", '#EB4904', "#2ECC40", "#36A2EB", "#36A2EB", "#36A2EB", "#36A2EB"],
            data: [this.stats.female, this.stats.male, this.stats.neutral_it, this.stats.neutral_ne, this.stats.neutral_one, this.stats.neutral_they, this.stats.neutral_ze]
          }
        ]
      }, options)
    },
  },
  mounted () {
    this.renderDoughnutChart();
  },
  watch: {
    stats: function () {
      this.renderDoughnutChart();
    }
  }
};
</script>
