<template>
  <div id='books-table'>
    <book-form v-if='showBookForm'
                  :book='newBook'
                  v-on:add-book='addBook'
                  v-on:hide-form='hideBookForm'></book-form>
    <button v-else class='button is-link'
            v-on:click='showBookForm = true'>+ Add Book</button>
    <br>
    <br>
    <table class='table is-bordered is-striped is-hoverable is-fullwidth'>
      <tbody>
        <tr>
          <th>ID</th>
          <th></th>
          <th>Title</th>
          <th>Author</th>
          <th>Publication Year</th>
          <th>Public Domain</th>
          <th>Status</th>
          <th>Payments #</th>
          <th>Users #</th>
        </tr>
        <tr v-for='book in books' v-bind:key='book.id'>
          <td>{{book.id}}</td>
          <td><a :href='bookEditUrl(book)'>Edit</a></td>
          <td>{{book.title}}</td>
          <td>{{book.author}}</td>
          <td>{{book.publication_year}}</td>
          <td>{{book.public_domain}}</td>
          <td>{{book.status}}</td>
          <td>{{book.payments_count}}</td>
          <td>{{book.users_count}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from '../api';
import BookForm from '../components/BookForm.vue'

export default {
  components: { BookForm },
  data() {
    return {
      books: [],
      newBook: {
        id: null,
        author: '',
        blurb: '',
        publication_year: '',
        public_domain: true,
        status: 'potential',
        title: '',
      },
      showBookForm: false,
    }
  },
  methods: {
    addBook: function (newBook) {
      this.books.push(newBook);
      this.hideBookForm();
    },
    bookEditUrl: function (book) {
      return '/books/' + book.slug + '/edit'
    },
    hideBookForm: function (e) {
      this.showBookForm = false;
    },
  },
  created() {
    api.getBooks().then(response => {
      this.books = response.data;
    });
  },
};
</script>
