<template>
  <div id='character-form'>
    <form>
      <Errors :errors='errors'></Errors>
      <div class='field'>
        <label class='label'>Name</label>
        <div class='control'>
          <input class='input' type='text' name='character[name]'
                 v-model.trim='characterAttributes.name'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Primacy</label>
        <div class='select'>
          <select name='character[primacy]' v-model='characterAttributes.primacy'>
            <option value=''></option>
            <option value='primary'>Primary</option>
            <option value='supporting'>Supporting</option>
            <option value='minor'>Minor</option>
            <option value='referenced'>Referenced</option>
          </select>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Original Gender</label>
        <div class='select'>
          <select name='character[original_gender]' v-model='characterAttributes.original_gender'>
            <option value=''></option>
            <option value='female'>Feminine 'She'</option>
            <option value='male'>Masculine 'He'</option>
            <option value='neutral_they'>Neutral 'They'</option>
            <option value='neutral_it'>Neutral 'It'</option>
            <option value='neutral_one'>Neutral 'One'</option>
            <option value='neutral_ne'>Neutral 'Ne'</option>
            <option value='neutral_ze'>Neutral 'Ze'</option>
          </select>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Color Hex</label>
        <div class='control'>
          <input class='input' type='text' name='character[color_hex]'
                 v-model.trim='characterAttributes.color_hex'
                 v-bind:style="{ color: '#ffffff', backgroundColor: characterAttributes.color_hex}">
          <button class='button is-small'
                  v-on:click='generateRandomColorHex'>Generate Random Color Hex!</button>
        </div>
      </div>
      <div class='field is-grouped'>
        <div class='control'>
          <button class='button is-link' v-on:click='createOrUpdateCharacter'>Submit</button>
        </div>
        <div class="control">
          <button class="button is-text" v-on:click='triggerHideForm'>Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '../api';
import Errors from '../components/Errors.vue'

export default {
  components: { Errors },
  props: ['bookId', 'character'],
  data() {
    return {
      characterAttributes: {
        color_hex: this.character.color_hex,
        name: this.character.name,
        original_gender: this.character.original_gender,
        primacy: this.character.primacy
      },
      errors: {},
    };
  },
  methods: {
    createCharacter: function () {
      api.createBookCharacter(this.bookId, this.characterAttributes)
        .then(response => {
          this.onSuccess(response, 'add-character');
        })
        .catch(error => {
          this.onErrors(error);
        });
    },
    createOrUpdateCharacter: function (e) {
      e.preventDefault();
      this.character.id == null ? this.createCharacter() : this.updateCharacter();
    },
    generateRandomColorHex: function (e) {
      e.preventDefault();
      // From https://stackoverflow.com/questions/1484506/random-color-generator
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      this.characterAttributes.color_hex = color;
    },
    onErrors: function (error) {
      this.errors = error.response.data.errors;
    },
    onSuccess: function (response, functionName) {
      this.$emit(functionName, response.data)
      this.errors = {};
    },
    triggerHideForm: function (e) {
      e.preventDefault();
      this.$emit('hide-form');
    },
    updateCharacter: function (e) {
      api
        .updateBookCharacter(this.bookId, this.character.id, this.characterAttributes)
        .then(response => {
          this.onSuccess(response, 'update-character')
        })
        .catch(error => {
          this.onErrors(error)
        });
    },
  },
};
</script>
