<template>
  <div id='stripe-checkout'>
    <!-- TODO: Handle errors in some way... -->
    <button class='button is-primary' v-on:click='openStripeCheckout'>{{ buttonText }}</button>
  </div>
</template>

<script>
import api from '../api';

export default {
  data () {
    return {
      errors: [],
      stripe: '',
    };
  },
  props: ['book', 'cast', 'product', 'userEmail', 'userId'],
  computed: {
    buttonText: function () {
      return '$' + this.displayPrice + ' Buy ' + this.product
    },
    displayPrice: function () {
      return (this.price / 100)
    },
    price: function () {
      var productPrice = ''
      if (this.product === 'Book') {
        productPrice = this.book.price
      } else if (this.product === 'Cast') {
        productPrice = this.book.cast_price
      } else {
        productPrice = this.book.paperback_price
      }
      return productPrice
    },
    productIsPaperback: function () {
      return this.product === 'Paperback'
    },
  },
  methods: {
    addressParams: function (addresses) {
      if (this.product === 'Paperback') {
        return {
          name: addresses.stripeShippingName,
          address1: addresses.shipping_address_line1,
          city: addresses.shipping_address_city,
          country: addresses.shipping_address_country,
          postal_code: addresses.shipping_address_zip,
          shipping_name: addresses.shipping_name,
          state: addresses.shipping_address_state,
          user_id: this.userId
        }
      } else {
        return {}
      }
    },
    openStripeCheckout (e) {
      e.preventDefault();
      this.stripe.open();
    },
    paymentParams: function (token, addresses) {
      return {
        address: this.addressParams(addresses),
        payment: {
          book_id: this.book.id,
          cast_id: this.cast === undefined ? null : this.cast.id,
          price: this.price,
          product: this.product.toLowerCase(),
          total_amount: this.price,
          user_id: this.userId,
        },
        stripeEmail: token.email,
        stripeToken: token.id,
      }
    },
    toCurrency: function (priceInCents) {
      return "$" + (priceInCents / 100)
    },
  },
  created(){
    this.stripe = StripeCheckout.configure({
      key: process.env.STRIPE_PUBLISHABLE_KEY,
      allowRememberMe: true,
      amount: parseInt(this.price),
      billingAddress: this.productIsPaperback,
      description: 'A Repaper Press ' + this.product,
      email: this.userEmail,
      image: require('../images/book_icon_closed_128x128.png'),
      locale: 'auto',
      name: this.book.title,
      shippingAddress: this.productIsPaperback,
      zipCode: true,
      token: (token, addresses) => {
        this.$emit('now-loading');
        api.createPayment(this.paymentParams(token, addresses)).then(response => {
          if (this.productIsPaperback) {
            this.cast.print_orders.push(response.data.print_order)
            this.$emit('stop-loading');
          } else {
            this.$emit('refresh-casts');
          }
        });
      }
    })
  },
  beforeDestroy () {
    this.stripe.close()
  }
};
</script>
