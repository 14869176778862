<template>
  <div id='gender-words-table'>
    <gender-word-form v-if='showGenderWordForm'
              :gender-word='genderWordForForm'
              v-on:add-gender-word='addGenderWord'
              v-on:update-gender-word='updateGenderWord'
              v-on:hide-form='hideGenderWordForm'></gender-word-form>
    <button v-else class='button is-link'
            v-on:click='showGenderWordForm = true'>+ Add Gender Word</button>
    <br>
    <br>
    <table class='table is-bordered is-striped is-hoverable is-fullwidth'>
      <tbody>
        <tr>
          <th>ID</th>
          <th></th>
          <th>Female</th>
          <th>Male</th>
          <th>Neutral 'It'</th>
          <th>Neutral 'One'</th>
          <th>Neutral 'Ne'</th>
          <th>Neutral 'They'</th>
          <th>Neutral 'Ze'</th>
        </tr>
        <tr v-for='genderWord in genderWords' v-bind:key='genderWord.id'>
          <td>{{genderWord.id}}</td>
          <td><button class='button' v-on:click='editGenderWord(genderWord)'>Edit</button></td>
          <td>{{genderWord.female}}</td>
          <td>{{genderWord.male}}</td>
          <td>{{genderWord.neutral_it}}</td>
          <td>{{genderWord.neutral_one}}</td>
          <td>{{genderWord.neutral_ne}}</td>
          <td>{{genderWord.neutral_they}}</td>
          <td>{{genderWord.neutral_ze}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from '../api';
import GenderWordForm from '../components/GenderWordForm.vue';

export default {
  components: { GenderWordForm },
  data() {
    return {
      genderWords: [],
      genderWordForForm: this.newGenderWordAttributes(),
      showGenderWordForm: false,
    }
  },
  methods: {
    addGenderWord: function (newGenderWord) {
      this.genderWords.push(newGenderWord);
      this.hideGenderWordForm();
    },
    editGenderWord: function (genderWord) {
      this.genderWordForForm = genderWord;
      this.showGenderWordForm = true;
    },
    hideGenderWordForm: function () {
      this.genderWordForForm = this.newGenderWordAttributes();
      this.showGenderWordForm = false;
    },
    newGenderWordAttributes: function () {
      return {
        id: null,
        female: '',
        male: '',
        neutral_it: '',
        neutral_one: '',
        neutral_ne: '',
        neutral_they: '',
        neutral_ze: '',
      };
    },
    updateGenderWord: function (updatedGenderWord) {
      var genderWordIndex = this.genderWords.findIndex(function(genderWord) {
        return genderWord.id === updatedGenderWord.id;
      });
      this.$set(this.genderWords, genderWordIndex, updatedGenderWord)
      this.genderWordForForm = this.newGenderWordAttributes();
      this.hideGenderWordForm();
    }
  },
  created() {
    api.getGenderWords().then(response => {
      this.genderWords = response.data;
    });
  },
};
</script>
