<template>
  <div id='cast-read'>
    <div class='has-text-centered'>
      <div class='select'>
        <select id='toc' v-on:change='navigateToc'></select>
      </div>
    </div>
    <div id='ereader' v-bind:style='{ height: height }'></div>
    <div id='prev' class='arrow' v-on:click='prevPage'>‹</div>
    <div id='next' class='arrow' v-on:click='nextPage'>›</div>
  </div>
</template>

<script>
import ePub from 'epubjs'
import Hammer from 'hammerjs'

export default {
  props: ['user_cast'],
  data () {
    return {
      gesturalControl: {},
      rendition: {},
    }
  },
  computed: {
    eBook: function () {
      return ePub(this.user_cast.epub.url);
    },
    height: function () {
      return window.innerHeight * 0.75 + 'px';
    },
    select: function () {
      return document.getElementById('toc');
    },
  },
  methods: {
    createToc: function (toc) {
      var docfrag = document.createDocumentFragment();
      toc.forEach( function(chapter) {
        var option = document.createElement('option');
        option.textContent = chapter.label;
        option.ref = chapter.href;
        docfrag.appendChild(option);
      });
      this.select.appendChild(docfrag);
    },
    localStorageKey: function () {
      return this.eBook.key() + '-locations';
    },
    navigateToc: function () {
      var index = this.select.selectedIndex,
        url = this.select.options[index].ref;
      this.rendition.display(url);
      return false;
    },
    nextPage: function () {
      this.rendition.next();
    },
    prevPage: function () {
      this.rendition.prev();
    },
    restoreLastLocation: function () {
      var lastLocation = localStorage.getItem(this.localStorageKey);
      if (lastLocation === null) {
        this.rendition.display();
      } else {
        this.rendition.display(lastLocation);
      }
    },
    setGesturalControl: function () {
      this.gesturalControl = new Hammer(document.getElementById('cast-read'));
      var component = this;
      this.gesturalControl.on('swipeleft', function() {
        component.nextPage();
      });
      this.gesturalControl.on('swiperight', function() {
        component.prevPage();
      });
    },
  },
  created() {
    this.rendition = this.eBook.renderTo('ereader', { height: '100%', width: '100%' });

    var component = this;
    this.eBook.ready.then( function() {
      component.setGesturalControl();
      component.restoreLastLocation();
    })

    this.eBook.loaded.navigation.then( function(toc) {
      component.createToc(toc);
    });
  },
  beforeDestroy () {
    localStorage.setItem(this.localStorageKey, this.rendition.currentLocation().start.cfi);
  }
};
</script>

<style>
#prev {
  left: 40px;
}
#next {
  right: 40px;
}
.arrow {
  position: absolute;
  top: 50%;
  margin-top: -32px;
  font-size: 64px;
  color: #E2E2E2;
  font-family: arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.arrow:hover {
  color: #777;
}
.arrow:active,
.arrow.active {
  color: #000;
}
#ereader {
  width: 80%;
  margin: 0 auto;
  margin-top: 5%;
  max-width: 1250px;
  z-index: -1;
  position: relative;
  overflow: hidden;
}
#ereader iframe {
  border: none;
}
@media only screen and (max-width: 550px) {
  .arrow {
    display: none;
  }
}
</style>
