<template>
  <div id='characters-table'>
    <table class='table is-bordered is-striped is-hoverable is-fullwidth'>
      <tbody>
        <tr>
          <th>ID</th>
          <th></th>
          <th>Color Hex</th>
          <th>Name</th>
          <th>Primacy</th>
          <th>Original Gender</th>
          <th>CGWs #</th>
        </tr>
        <tr v-for='character in characters' v-bind:key='character.id'>
          <td>{{character.id}}</td>
          <td><button class='button' v-on:click='editCharacter(character)'>Edit</button></td>
          <td v-bind:style='{ color: "#ffffff", backgroundColor: character.color_hex}'>
            {{character.color_hex}}
          </td>
          <td>{{character.name}}</td>
          <td>{{character.primacy}}</td>
          <td>{{character.original_gender}}</td>
          <td>{{character.cgws_count}}</td>
        </tr>
      </tbody>
    </table>
    <br>
    <character-form v-if='showCharacterForm'
                    :book-id='bookId'
                    :character='characterForForm'
                    v-on:add-character='addCharacter'
                    v-on:update-character='updateCharacter'
                    v-on:hide-form='hideCharacterForm'></character-form>
    <button v-else class='button is-link'
            v-on:click='showCharacterForm = true'>+ Add Character</button>
  </div>
</template>

<script>
import api from '../api';
import CharacterForm from '../components/CharacterForm.vue'

export default {
  components: { CharacterForm },
  props: [ 'bookId' ],
  data() {
    return {
      characters: [],
      characterForForm: this.newCharacterAttributes(),
      showCharacterForm: false,
    }
  },
  methods: {
    addCharacter: function (newCharacter) {
      this.characters.push(newCharacter);
      this.hideCharacterForm();
    },
    editCharacter: function (character) {
      this.characterForForm = character;
      this.showCharacterForm = true;
    },
    hideCharacterForm: function () {
      this.characterForForm = this.newCharacterAttributes();
      this.showCharacterForm = false;
    },
    newCharacterAttributes: function () {
      return {
        id: null,
        color_hex: '',
        name: '',
        original_gender: '',
        primacy: ''
      };
    },
    updateCharacter: function (updatedCharacter) {
      var characterIndex = this.characters.findIndex(function(character) {
        return character.id === updatedCharacter.id;
      });
      this.$set(this.characters, characterIndex, updatedCharacter);
      this.characterForForm = this.newCharacterAttributes();
      this.hideCharacterForm();
    }
  },
  created() {
    api.getBookCharacters(this.bookId).then(response => {
      this.characters = response.data;
    });
  },
};
</script>
