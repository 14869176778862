<template>
  <div id='cast-character-edit' class='box is-shadowless'>
    <div class='columns is-vcentered'>
      <div class='column is-6' v-bind:style='avatarStyle'></div>
      <div class='column'>
        <div class=''>
          <p class='title is-6'>
            {{character.name}}
            <span class='help'>(Originally {{character.original_gender_readable}})</span>
          </p>
        </div>
      </div>
      <div class='column'>
        <form id='create_cast_character'>
          <div class='select'>
            <select name='cast_character[gender]'
                    v-model='character.selected_gender'
                    v-on:change='updateOrCreateCastCharacter'
                    v-on:blur='reset'>
              <option value='female'>Feminine 'She'</option>
              <option value='male'>Masculine 'He'</option>
              <option value='neutral_they'>Neutral 'They'</option>
              <option value='neutral_it'>Neutral 'It'</option>
              <option value='neutral_one'>Neutral 'One'</option>
              <option value='neutral_ne'>Neutral 'Ne'</option>
              <option value='neutral_ze'>Neutral 'Ze'</option>
            </select>
          </div>
          <success v-if='saved'></success>
        </form>
      </div>
      <div class='column has-text-centered'>{{ character.primacy }}</div>
      <div class='column has-text-centered'>{{ character.cgws_count }} word references</div>
    </div>
  </div>
</template>

<script>
import api from '../api';
import Success from '../components/Success.vue';

export default {
  components: { Success },
  props: ['castId', 'character'],
  data () {
    return {
      saved: false
    }
  },
  computed: {
    avatarStyle: function () {
      return {
        backgroundColor: this.character.color_hex,
        borderRadius: '50%',
        display: 'inline-block',
        height: '70px',
        width: '70px'
      };
    }
  },
  methods: {
    formatCastCharacterParams: function () {
      return {
        character_id: this.character.id,
        gender: this.character.selected_gender
      }
    },
    reset: function () {
      this.saved = false
    },
    updateOrCreateCastCharacter: function () {
      let params = this.formatCastCharacterParams()
      api.updateOrCreateCastCharacter(this.castId, params).then(response => {
        this.$emit('update-stats', response.data);
        this.saved = true
      });
    },
  },
};
</script>
