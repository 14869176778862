<template>
  <div id='book-show'>
    <major-loader v-if='isLoading' v-bind:class="{'is-active': isLoading}"></major-loader>
    <div class='columns'>
      <aside class='column is-one-third section has-text-centered'>
        <div>
          <img id='book-cover' v-bind:src='book.ebook_cover_image_url' v-bind:alt='book.title'>
        </div>
        <div v-if='castHasLoaded'>
          <!-- If no user show signin link disguised as book payment button and wishlist button -->
          <div v-if='!signedInUser'>
            <br>
            <div>
              <a class='button is-primary' href='/sign_in'>{{ toCurrency(book.price) }} Buy Book</a>
            </div>
            <br>
            <div>
              <a class='button' href='/sign_in'>
                <span class='icon'><i class='fas fa-heart'></i></span>
                <span>Add to Wishlist</span>
             </a>
            </div>
            <br>
          </div>

          <!-- If user doesn't own book show real book payment button and wishlist button -->
          <div v-else-if='!userOwnsBook'>
            <br>
            <stripe-checkout :book='book'
                             :product='product'
                             :user-email='userEmail'
                             :user-id='userId'
                             v-on:now-loading='isLoading = true'
                             v-on:stop-loading='isLoading = false'
                             v-on:refresh-casts='fetchCasts'></stripe-checkout>
            <br>
            <wishlist-button :book='book'></wishlist-button>
            <br>
          </div>

          <!-- If the user is actively editing a cast don't show anything -->
          <div v-else-if='userHasEditableCast'></div>

          <!-- If userSignedIn && userOwnsBook && not editing a cast, show cast payment button -->
          <div v-else>
            <br>
            <stripe-checkout :book='book'
                             :product='product'
                             :user-email='userEmail'
                             :user-id='userId'
                             v-on:now-loading='isLoading = true'
                             v-on:stop-loading='isLoading = false'
                             v-on:refresh-casts='fetchCasts'></stripe-checkout>
            <br>
          </div>
        </div>

        <div>
          <a v-bind:href='bookReviewsUrl'><button class='button is-text'>Read Reviews</button></a>
        </div>
        <br>
        <p> {{ book.blurb}} </p>
        <br>
        <div class="casts-list" v-if='userOwnsBook' v-for='cast in casts' v-bind:key='cast.id'>
          <casts-list-item :cast='cast' v-on:display-cast='updateDisplayedCast'></casts-list-item>
        </div>
      </aside>

      <section id='cast-display' class='column is-two-thirds section'>
        <cast-edit v-if='displayedCastIsEditable'
                   :cast='displayedCast'
                   :casts='casts'
                   v-on:now-loading='isLoading = true'
                   v-on:stop-loading='isLoading = false'
                   v-on:refresh-casts='fetchCasts'></cast-edit>
        <cast-show v-else
                   :book='book'
                   :cast='displayedCast'
                   :user-email='userEmail'
                   :user-id='userId'
                   :user-owns-book='userOwnsBook'
                   v-on:now-loading='isLoading = true'
                   v-on:stop-loading='isLoading = false'></cast-show>
      </section>
    </div>
  </div>
</template>

<script>
import api from '../api.js';
import CastEdit from '../components/CastEdit.vue'
import CastShow from '../components/CastShow.vue'
import CastsListItem from '../components/CastsListItem.vue'
import MajorLoader from '../components/MajorLoader.vue'
import StripeCheckout from '../components/StripeCheckout.vue'
import WishlistButton from '../components/WishlistButton.vue'

export default {
  components: {CastEdit, CastShow, CastsListItem, MajorLoader, StripeCheckout, WishlistButton},
  props: ['bookId', 'user-email', 'user-id'],
  data() {
    return {
      book: {},
      casts: [], // If user owns book this will be an array of 2 or more, else just one object
      displayedCast: {},
      displayedCastIsEditable: false,
      isLoading: true,
      castHasLoaded: false,
      userHasEditableCast: false,
      userOwnsBook: false
    }
  },
  computed: {
    bookReviewsUrl () {
      return '/books/' + this.book.slug + '/reviews';
    },
    product () {
      var eligibleToAddCast = this.userOwnsBook && !this.userHasEditableCast
      return eligibleToAddCast ? 'Cast' : 'Book'
    },
    signedInUser () {
      return this.userId != null
    },
  },
  methods: {
    fetchBook: function () {
      api.getBook(this.bookId).then(response => {
        this.book = response.data;
        this.fetchCasts();
      });
    },
    fetchCasts: function () {
      api.getCasts(this.book.id).then(response => {
        this.casts = response.data;
        this.updateDisplayedCast(this.casts[0] || this.casts);
        this.userOwnsBook = this.casts.length > 1; // I could also pass in user_book...
        this.userHasEditableCast = this.userOwnsBook && this.casts[0].editable == true;
        this.isLoading = false;
      });
    },
    scrollToCast: function () {
      if (window.innerWidth > 768) {
        $('html, body').animate({ scrollTop: 0 }, 'slow');
      }
      else {
        var scrollIntoViewOptions = { behavior: 'smooth', block: 'start' };
        $( document ).ready(function() {
          document.getElementById('cast-display').scrollIntoView(scrollIntoViewOptions);
        });
      }
    },
    toCurrency: function (priceInCents) {
      return "$" + (priceInCents / 100)
    },
    updateDisplayedCast: function (cast) {
      this.displayedCast = cast;
      this.displayedCastIsEditable = cast.editable === true;
    },
  },
  watch: {
    displayedCast: function () {
      if (this.displayedCast.id !== undefined) {
        this.castHasLoaded = true;
      }
      if (this.userOwnsBook) {
        this.scrollToCast();
      }
    }
  },
  created () {
    this.fetchBook();
  }
};
</script>

<style scoped>
#book-cover {
  height: 300px;
}
</style>
