<template>
  <div id='users-table'>
    <table class='table is-bordered is-striped is-hoverable is-fullwidth'>
      <tbody>
        <tr>
          <th>ID</th>
          <th>Full Name</th>
          <th>Email</th>
          <th>Date Joined</th>
          <th>Books #</th>
          <th>Payments #</th>
        </tr>
        <tr v-for='user in users' v-bind:key='user.id'>
          <td>{{user.id}}</td>
          <td>{{user.full_name}}</td>
          <td>{{user.email}}</td>
          <td>{{user.created_at}}</td>
          <td>{{user.books_count}}</td>
          <td>{{user.payments_count}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from '../api';

export default {
  data() {
    return {
      users: [],
    }
  },
  created() {
    api.getUsers().then(response => {
      this.users = response.data;
    });
  },
};
</script>
