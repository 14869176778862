<template>
  <div id='print-orders-table'>
    <table class='table is-bordered is-striped is-hoverable is-fullwidth'>
      <tbody>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Status</th>
          <th>Requester</th>
          <th>Book Title</th>
          <th>Cast</th>
          <th>Shipping Address</th>
        </tr>
        <tr v-for='printOrder in printOrders' v-bind:key='printOrder.id'>
          <td>{{printOrder.id}}</td>
          <td>{{printOrder.created_at}}</td>
          <td>
            <div class='control'>
              <div class='select'>
                <select name='print_order[status]' v-model='printOrder.status'
                        v-on:change='updatePrintOrderStatus(printOrder)'>
                  <option value='pending'>Pending</option>
                  <option value='printing'>Printing</option>
                  <option value='shipping'>Shipping</option>
                  <option value='delivered'>Delivered</option>
                </select>
              </div>
            </div>
          </td>
          <td>
            {{printOrder.user.full_name}}<br>
            {{printOrder.user.email}}
          </td>
          <td>{{printOrder.book.title}}</td>
          <td><a :href='htmlUrl(printOrder)' download>HTML</a></td>
          <td class='address-cell'>
            {{printOrder.address.shipping_name}}<br>
            {{printOrder.address.address1}}<br>
            {{printOrder.address.city}}, {{printOrder.address.state}}
              {{printOrder.address.postal_code}}<br>
            {{printOrder.address.country}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from '../api';

export default {
  data() {
    return {
      printOrders: [],
    }
  },
  methods: {
    htmlUrl: function (printOrder) {
      return printOrder.cast_html_url
    },
    updatePrintOrderStatus: function (printOrder) {
      api.updatePrintOrder(printOrder.id, printOrder);
    }
  },
  created() {
    api.getPrintOrders().then(response => {
      this.printOrders = response.data;
    });
  },
};
</script>
