<template>
  <div id='admin-dashboard'>
    <div class='columns'>
      <aside class='column is-one-fifth menu section'>
        <p class='menu-label'>Books</p>
        <ul class='menu-list'>
          <li>
            <a href='#books' v-on:click='selectTab'>Books</a>
          </li>
          <li>
            <a href='#gwords' v-on:click='selectTab'>Gender Words</a>
          </li>
        </ul>
        <p class='menu-label'>Users</p>
        <ul class='menu-list'>
          <li>
            <a href='#users' v-on:click='selectTab'>Users</a>
          </li>
        </ul>
        <p class='menu-label'>Purchases</p>
        <ul class='menu-list'>
          <li>
            <a href='#payments' v-on:click='selectTab'>Payments</a>
          </li>
          <li>
            <a href='#orders' v-on:click='selectTab'>Print Orders</a>
          </li>
        </ul>
        <p class='menu-label'>Articles</p>
        <ul class='menu-list'>
          <li>
            <a href='#articles' v-on:click='selectTab'>Articles</a>
          </li>
        </ul>
      </aside>
      <section class='column is-four-fifths section'>
        <div id='books' class='tab-content active-tab'>
          <h1 class='title is-3'>Books</h1>
          <books-table></books-table>
        </div>
        <div id='gwords' class='tab-content'>
          <h1 class='title is-3'>Gender Words</h1>
          <gender-words-table></gender-words-table>
        </div>
        <div id='users' class='tab-content'>
          <h1 class='title is-3'>Users</h1>
          <users-table></users-table>
        </div>
        <div id='payments' class='tab-content'>
          <h1 class='title is-3'>Payments</h1>
          <payments-table></payments-table>
        </div>
        <div id='orders' class='tab-content'>
          <h1 class='title is-3'>Print Orders</h1>
          <print-orders-table></print-orders-table>
        </div>
        <div id='articles' class='tab-content'>
          <h1 class='title is-3'>Articles</h1>
          <articles-table></articles-table>
        </div>
      </section>
    </div>
  </div>
</template>


<script>
import api from "../api";
import ArticlesTable from '../components/ArticlesTable.vue'
import BooksTable from '../components/BooksTable.vue'
import GenderWordsTable from '../components/GenderWordsTable.vue'
import PaymentsTable from '../components/PaymentsTable.vue'
import PrintOrdersTable from '../components/PrintOrdersTable.vue'
import UsersTable from '../components/UsersTable.vue'

export default {
  components: { ArticlesTable, BooksTable, GenderWordsTable, PaymentsTable, PrintOrdersTable, UsersTable },
  methods: {
    selectTab: function (e) {
      e.preventDefault();
      var selectedTabId = e.target.hash
      $('.active-tab').removeClass('active-tab');
      $(selectedTabId).addClass('active-tab');
    },
  }
};
</script>

<style>
.tab-content {
  display: none;
}
.active-tab {
  display: block;
}
</style>
