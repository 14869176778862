import Vue from 'vue/dist/vue.esm'
import Turbolinks from 'turbolinks';
import TurbolinksAdapter from 'vue-turbolinks'

Turbolinks.start();
Vue.use(TurbolinksAdapter);

import Stylesheet from '../stylesheet.scss'

import AdminDashboard from '../views/AdminDashboard.vue'
import BookEdit from '../views/BookEdit.vue'
import BookShow from '../views/BookShow.vue'
import CastRead from '../views/CastRead.vue'
import UserDashboard from '../views/UserDashboard.vue'

import BookCard from '../components/BookCard.vue'
import BooksIndex from '../components/BooksIndex.vue'
import CharacterShow from '../components/CharacterShow.vue'
import DoughnutChart from '../components/DoughnutChart.vue'
import RepaperHeader from '../components/RepaperHeader.vue'
import RepaperingDemo from '../components/RepaperingDemo.vue'
import ReviewShow from '../components/ReviewShow.vue'


document.addEventListener('turbolinks:load', () => {
  var element = document.getElementById('repaper-app-vue-instance')
  if (element != undefined) {
    const app = new Vue({
      el: element,
      components: { AdminDashboard, BookCard, BookEdit, BookShow, BooksIndex, CastRead, CharacterShow, DoughnutChart, RepaperHeader, RepaperingDemo, ReviewShow, UserDashboard }
    });
  }
});
