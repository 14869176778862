<template>
  <div id='cast-show'>
    <!-- If the user owns the book, show the download, paperback, and review options. -->
    <div v-if='userOwnsBook'>
      <h2 class='title is-4'> {{ cast.user_cast.title }} </h2>
      <div class='box'>
        <div class='level'>
          <div class='is-grouped'>
            <a v-bind:href='cast.user_cast.epub_url'>
              <button class='button is-primary'>
                <span class='icon'><i class='fas fa-file-download'></i></span>
                <span>EPUB</span>
              </button>
            </a>
          </div>
          <div class='level-right'>
            <a v-bind:href='castReadUrl' target='_blank'>
              <button class='button is-primary'>
                <span class='icon'><i class='fas fa-desktop'></i></span>
                <span><strong>Read Online</strong></span>
              </button>
            </a>
          </div>
        </div>
        <p class='help'>EPUB format is good for most ereader devices.</p>
      </div>
      <div v-if='dedicationOrDescriptionPresent' class='box'>
        <p v-if='cast.user_cast.description != null'>
          <strong>Description: </strong>{{ cast.user_cast.description }}
        </p>
        <p v-if='cast.user_cast.dedication != null'>
          <strong>Dedication: </strong>{{ cast.user_cast.dedication }}
        </p>
      </div>
      <div class='level box'>
        <div class='level-left'>
          <div v-if='cast.print_orders.length > 0'>
            <div v-for='printOrder in cast.print_orders' v-bind:key='printOrder.id'>
              <p>You ordered a paperback of this cast on {{printOrder.created_at}}. It is {{printOrder.status}}!</p>
            </div>
          </div>
          <div v-else>
            <p>You can order a paperback of this cast!</p>
          </div>
        </div>
        <div class='level-right'>
          <stripe-checkout :book='book' :product='stripeProduct'
                     :cast='cast'
                     :user-email='userEmail'
                     :user-id='userId'
                     v-on:now-loading='bubbleNowLoadingEvent'
                     v-on:stop-loading='bubbleStopLoadingEvent'></stripe-checkout>
        </div>
      </div>
      <div class='box'>
        <review-form v-if='showReviewForm' :cast='cast'
                       v-on:hide-form='showReviewForm = false'></review-form>
        <div v-else-if='cast.review === null'>
          <div class='level'>
            <div class='level-left'>
              <p>Read it? Let the world know what you learned!</p>
            </div>
            <div class='level-right'>
              <button class='button is-primary is-outlined'
                      v-on:click='showReviewForm = true'>Review This Cast</button>
            </div>
          </div>
        </div>
        <div v-else>
          <div class='is-clearfix'>
            <review-show :review='cast.review' :user-id='userId'></review-show>
            <div class='is-grouped is-pulled-right'>
              <button class='button is-text'
                      v-on:click='destroyReview'>Delete Review</button>
              <button class='button is-primary is-outlined'
                      v-on:click='showReviewForm = true'>Edit Review</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- If the page is loading, show a blank background. -->
    <div v-else-if='isLoading'></div>
    <!-- If the user doesn't own the book, show the Original Cast heading. -->
    <div v-else>
      <h2 class='title is-4 is-marginless'> Original Cast </h2>
      <p class='help'>Purchase the book to start customizing your own cast!</p>
    </div>
    <br>
    <div v-if='showCastStatistics' class='columns'>
      <div class='column has-text-centered'>
        <doughnut-chart :stats='cast.character_gender_stats'
                        :title-is-characters='true'
                        :height='200'></doughnut-chart>
      </div>
      <div class='column has-text-centered'>
        <doughnut-chart :stats='cast.word_gender_stats'
                        :title-is-characters='false'
                        :height='200'></doughnut-chart>
      </div>
    </div>
    <div v-for='character in characters' v-bind:key='character.id'>
      <character-show :character='character' :original-cast='cast.original_cast'></character-show>
    </div>
  </div>
</template>

<script>
import api from "../api";
import CharacterShow from "../components/CharacterShow";
import DoughnutChart from "../components/DoughnutChart";
import ReviewForm from "../components/ReviewForm";
import ReviewShow from "../components/ReviewShow";
import StripeCheckout from "../components/StripeCheckout";

export default {
  components: { CharacterShow, DoughnutChart, ReviewForm, ReviewShow, StripeCheckout },
  props: ['book', 'cast', 'userEmail', 'userId', 'userOwnsBook'],
  data () {
    return {
      characters: [],
      isLoading: true,
      showReviewForm: false,
      showCastStatistics: false,
      stripeProduct: 'Paperback',
    }
  },
  computed: {
    castReadUrl: function () {
      return '/books/' + this.book.slug + '/user_casts/' + this.cast.user_cast.slug + '/read'
    },
    dedicationOrDescriptionPresent: function () {
      return this.cast.user_cast.dedication != null || this.cast.user_cast.description != null;
    },
  },
  methods: {
    bubbleNowLoadingEvent: function () {
      this.$emit('now-loading');
    },
    bubbleStopLoadingEvent: function () {
      this.$emit('stop-loading');
    },
    destroyReview: function () {
      let warning = "Are you sure you want to delete this review? This cannot be undone."
      if (confirm(warning)) {
        api.destroyCastReview(this.cast.id, this.cast.review.id)
          .then(response => { this.cast.review = null })
      }
    },
    fetchCharacters: function () {
      api.getCastCharacters(this.cast.id).then(response => {
        this.characters = response.data;
        this.isLoading = false;
      });
    },
  },
  watch: {
    cast: function () {
      this.fetchCharacters();
      this.showCastStatistics = true;
      this.showReviewForm = false;
    }
  },
  created () {
    if (this.cast.id !== undefined) {
      this.fetchCharacters();
      this.showCastStatistics = true;
    }
  }
};
</script>
