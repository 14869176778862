<template>
  <div id='book-card' class='column is-one-third'>
    <div class='card has-text-centered'>
      <div class='card-content'>
        <p class='title is-4'>{{book.title}}</p>
        <p class='subtitle'>by {{book.author}}</p>
        <p><strong>{{copyrightStatus}}</strong></p>
        <br>
        <p>{{book.blurb}}</p>
        <br>
        <div v-if="book.status === 'repaperable'">
          <a class='button is-primary' v-bind:href='bookUrl'>Choose Genders</a>
        </div>
        <div v-else>
          <wishlist-button v-if='signedInUser'
                           v-on:refetch-books='bubbleRefetchBooksEvent'
                           :book='book'
                           :is-primary='true'></wishlist-button>
          <a v-else class='button is-primary' href='/sign_in'>
            <span class='icon'><i class='fas fa-heart'></i></span>
            <span>Request This Book</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WishlistButton from '../components/WishlistButton'

export default {
  components: { WishlistButton },
  props: ['book', 'user-id'],
  computed: {
    bookUrl: function () {
      return '/books/' + this.book.slug
    },
    copyrightStatus: function () {
      return this.book.public_domain ? 'Public Domain' : 'Under Copyright'
    },
    signedInUser () {
      return this.userId != null
    },
  },
  methods: {
    bubbleRefetchBooksEvent: function () {
      this.$emit('refetch-books')
    },
  },
};
</script>
