<template>
  <div id='character-show' class='box is-shadowless'>
    <div class='columns is-vcentered'>
      <div class='column is-6' v-bind:style='avatarStyle'></div>
      <div class='column'>
        <p class='title is-6'>
          {{ character.name }}
          <span class='help' v-if='originalCast != true'>
            (Originally {{ character.original_gender_readable }})
          </span>
        </p>
      </div>
      <div class='column has-text-centered'>{{ character.selected_gender_readable }}</div>
      <div class='column has-text-centered'>{{ character.primacy }}</div>
      <div class='column has-text-centered'>{{ character.cgws_count }} word references</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['character', 'original-cast'],
  computed: {
    avatarStyle: function () {
      return {
        backgroundColor: this.character.color_hex,
        borderRadius: '50%',
        display: 'inline-block',
        height: '70px',
        width: '70px'
      };
    },
  },
};
</script>
