<template>
  <div id='chapter-form'>
    <form>
      <Errors :errors='errors'></Errors>
      <div class='field'>
        <label class='label'>Ordinal</label>
        <div class='control'>
          <input class='input' type='text' name='chapter[ordinal]'
                 v-model.trim='chapterAttributes.ordinal'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Text</label>
        <textarea class='textarea' type='text-area' rows='18' name='chapter[text]'
                 v-model.trim='chapterAttributes.text'></textarea>
      </div>
      <div class='field is-grouped'>
        <div class='control'>
          <button class='button is-link' v-on:click='createOrUpdateChapter'>Submit</button>
        </div>
        <div class="control">
          <button class="button is-text" v-on:click='triggerHideForm'>Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '../api';
import Errors from '../components/Errors.vue'

export default {
  components: { Errors },
  props: ['bookId', 'chapter'],
  data() {
    return {
      chapterAttributes: {
        ordinal: this.chapter.ordinal,
        text: this.chapter.text,
      },
      errors: {},
    };
  },
  methods: {
    createChapter: function () {
      api.createBookChapter(this.bookId, this.chapterAttributes)
        .then(response => {
          this.onSuccess(response, 'add-chapter')
        })
        .catch(error => {
          this.onErrors(error);
        });
    },
    createOrUpdateChapter: function (e) {
      e.preventDefault();
      this.chapter.id == null ? this.createChapter() : this.updateChapter();
    },
    onErrors: function (error) {
      this.errors = error.response.data.errors;
    },
    onSuccess: function (response, functionName) {
      this.$emit(functionName, response.data)
      this.errors = {};
    },
    triggerHideForm: function (e) {
      e.preventDefault();
      this.$emit('hide-form');
    },
    updateChapter: function () {
      var warning = "Are you sure this chapter has not been processed yet, or that your edits will not change the text indexing, or you will manually make index corrections via the console, heaven help you?"
      if (confirm(warning)) {
        api.updateBookChapter(this.bookId, this.chapter.id, this.chapterAttributes)
          .then(response => {
            this.onSuccess(response, 'update-chapter')
          })
          .catch(error => {
            this.onErrors(error)
          });
      }
    }
  },
};
</script>
