<template>
  <div id='cast-list-item'>
    <div class='columns'>
      <div class='column'>
        <p> {{ cast.user_cast.title }} </p>
      </div>
      <div class='column'>
        <a class='button is-primary is-outlined' v-on:click="$emit('display-cast', cast)">
          <span class='icon'><i class='fas' v-bind:class='faIcon'></i></span>
          <span>{{ buttonText }}</span>
        </a>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  props: ["cast"],
  computed: {
    buttonText: function () {
      return this.cast.editable === true ? "Edit" : "View";
    },
    faIcon: function () {
      return this.cast.editable === true ? 'fa-edit' : 'fa-book-open';
    }
  },
};
</script>
