import axios from "axios";

const API_URL = process.env.API_URL;

// `baseURL` is prepended to URLs passed to axios
axios.defaults.baseURL = API_URL;

// POST with JSON content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

// `xsrfCookieName` is the name of the cookie  to use as a value for xsrf token.
// Default is 'XSRF-TOKEN'
axios.defaults.xsrfCookieName = "CSRF-TOKEN";

// `xsrfHeaderName` is the name of the http header that carries the xsrf token value.
// Default is 'X-XSRF-TOKEN'
axios.defaults.xsrfHeaderName = "X-CSRF-Token";

// Cannot use wildcard Access-Control-Allow-Origin when credentials flag is true.
// Rack CORS is configured to allow '*' in all environments except production.
// Therefore 'withCredentials' should be false in every environment except production.
axios.defaults.withCredentials = process.env.NODE_ENV === 'production';

export default {
  bulkUpdateCast(castId, transformation, transformationParams) {
    return axios.put(`/api/casts/${castId}/${transformation}`, transformationParams)
  },
  createBook (bookParams) {
    return axios.post(`/api/books`, { book: bookParams })
  },
  createBookChapter (bookId, chapterParams) {
    return axios.post(`/api/books/${bookId}/chapters`, { chapter: chapterParams });
  },
  createBookCharacter (bookId, characterParams) {
    return axios.post(`/api/books/${bookId}/characters`, { character: characterParams });
  },
  createGenderWord (genderWordParams) {
    return axios.post(`/api/gender_words`, { gender_word: genderWordParams })
  },
  createPayment (paymentParams) {
    return axios.post(`/api/payments`, paymentParams);
  },
  createCastReview (castId, reviewParams) {
    return axios.post(`/api/casts/${castId}/reviews`, reviewParams);
  },
  createUpvote (reviewId) {
    return axios.post(`/api/upvotes`, { upvote: {review_id: reviewId} })
  },
  createWishlisting (bookId) {
    return axios.post(`/api/wishlistings`, { wishlisting: {book_id: bookId} })
  },
  deleteSession() {
    return axios.delete(`/sign_out`)
  },
  destroyCastReview(castId, reviewId) {
    return axios.delete(`/api/casts/${castId}/reviews/${reviewId}`)
  },
  destroyWishlisting(wishlistingId) {
    return axios.delete(`/api/wishlistings/${wishlistingId}`)
  },
  generateEbook (castId) {
    return axios.put(`/api/casts/${castId}/generate_ebook`)
  },
  getArticles () {
    return axios.get(`/api/articles`);
  },
  getBook (bookId) {
    return axios.get(`/api/books/${bookId}`);
  },
  getBooks (booksParams) {
    return axios.get(`/api/books`, { params: booksParams });
  },
  getBookChapters (bookId) {
    return axios.get(`/api/books/${bookId}/chapters`);
  },
  getBookCharacters (bookId) {
    return axios.get(`/api/books/${bookId}/characters`);
  },
  getCastCharacters (castId) {
    return axios.get(`/api/casts/${castId}/characters`);
  },
  getCasts (bookId) {
    return axios.get('/api/casts', { params: { book_id: bookId } });
  },
  getCastStatistics (castId) {
    return axios.get(`/api/casts/${castId}/statistics`);
  },
  getGenderWords () {
    return axios.get(`/api/gender_words`);
  },
  getPayments () {
    return axios.get(`/api/payments`);
  },
  getPrintOrders () {
    return axios.get(`/api/print_orders`)
  },
  getUsers () {
    return axios.get(`/api/users`);
  },
  getUserRequestedBooks (userId) {
    return axios.get(`/api/users/${userId}/books?requested_books=true`);
  },
  getUserWishlistedBooks (userId) {
    return axios.get(`/api/users/${userId}/books?wishlisted_books=true`);
  },
  processChapterText (chapterId) {
    return axios.put(`/api/chapters/${chapterId}/process_text`)
  },
  updateBook (bookId, bookParams) {
    return axios.put(`/api/books/${bookId}`, { book: bookParams })
  },
  updateBookChapter (bookId, chapterId, chapterParams) {
    return axios.put(`/api/books/${bookId}/chapters/${chapterId}`, { chapter: chapterParams })
  },
  updateBookCharacter (bookId, characterId, characterParams) {
    return axios.put(`/api/books/${bookId}/characters/${characterId}`,
      { character: characterParams });
  },
  updateCastReview (castId, reviewId, reviewParams) {
    return axios.put(`/api/casts/${castId}/reviews/${reviewId}`, { review: reviewParams });
  },
  updateGenderWord (genderWordId, genderWordParams) {
    return axios.put(`/api/gender_words/${genderWordId}`, { gender_word: genderWordParams })
  },
  updateOrCreateCastCharacter (castId, castCharacterParams) {
    return axios.post(`/api/casts/${castId}/cast_characters`, { cast_character: castCharacterParams });
  },
  updatePrintOrder (printOrderId, printOrderParams) {
    return axios.put(`api/print_orders/${printOrderId}`, { print_order: printOrderParams })
  },
  updateUser (userId, userParams) {
    return axios.put(`/api/users/${userId}`, { user: userParams });
  },
  updateUserCast (userCastId, userCastParams) {
    return axios.put(`/api/user_casts/${userCastId}`, { user_cast: userCastParams });
  },
};


// TODO: Use something like this, if necessary...
// Intercept all 401 Unauthorized responses before
// they are handled by `then` or `catch`

// axios.interceptors.response.use(
//   function(response) {
//     return response;
//   },
//   function(error) {
//     if (!error.response) {
//       // network error
//       alert(`Unable to access API: ${error.message}`);
//     } else if (error.response.status == 401) {
//       console.error("Unauthorized!");
//     }
//     return Promise.reject(error);
//   }
// );
