<template>
  <div id='user-dashboard'>
    <div class='columns'>
      <aside class='column is-one-fifth menu section'>
        <p class='menu-label'>Your Books</p>
        <ul class='menu-list'>
          <li>
            <a href='#library' v-on:click='selectTab'>Library</a>
          </li>
          <li>
            <a href='#wishlist' v-on:click='selectTab'>Wishlist</a>
          </li>
          <li>
            <a href='#requests' v-on:click='selectTab'>Requested Books</a>
          </li>
        </ul>
        <p class='menu-label'>Your Settings</p>
        <ul class='menu-list'>
          <li>
            <a href='#account'v-on:click='selectTab'>Account</a>
          </li>
          <li v-if='user.site_admin == true'>
            <a href='/admin'>Admin Dashboard</a>
          </li>
        </ul>
      </aside>
      <section class='column is-four-fifths section'>
        <div id='library' class='tab-content active-tab'>
          <h2 class='title is-3'>Library</h2>
          <div v-if='purchasedBooks.length > 0' class='columns is-multiline'>
            <book-card :book='book' v-for='book in purchasedBooks' v-bind:key='book.id'></book-card>
          </div>
          <div v-else>
            <p>You haven't repapered any books yet! Get started <a href='/books'>here.</a></p>
          </div>
        </div>
        <div id='wishlist' class='tab-content'>
          <h2 class='title is-3'>Wishlist</h2>
          <div v-if='wishlistedBooks.length > 0' class='columns is-multiline'>
            <book-card v-for='book in wishlistedBooks' v-bind:key='book.id'
                       v-on:refetch-books='fetchWishlistedBooks'
                       :book='book'
                       :user-id='user.id'></book-card>
          </div>
          <div v-else>
            <p>You haven't wishlisted any books yet! Get started <a href='/books'>here.</a></p>
          </div>
        </div>
        <div id='requests' class='tab-content'>
          <h2 class='title is-3'>Requested Books</h2>
          <div v-if='requestedBooks.length > 0'>
            <div class='content'>
              <p>We'll send you an email when these books become repaperable.</p>
            </div>
            <div class='columns is-multiline'>
              <book-card v-for='book in requestedBooks' v-bind:key='book.id'
                         v-on:refetch-books='fetchRequestedBooks'
                         :book='book'
                         :user-id='user.id'></book-card>
            </div>
          </div>
          <div v-else>
            <p>You haven't requested any books yet! Get started <a href='/books/potentials'>here.</a></p>
          </div>
        </div>
        <div id='account' class='tab-content'>
          <h2 class='title is-3'>Account</h2>
          <div class='columns'>
            <div class='column is-two-thirds'>
              <form id='edit-user'>
                <Errors :errors='errors'></Errors>
                <div class='field'>
                  <label class='label'>First Name</label>
                  <div class='control'>
                    <input class='input' type='text' name='user[first_name]' ref='first_name'
                           v-on:blur='jankyReset'
                           v-model.trim='userAttributes.first_name'>
                  </div>
                </div>
                <div class='field'>
                  <label class='label'>Last Name</label>
                  <div class='control'>
                    <input class='input' type='text' name='user[last_name]'
                           v-model.trim='userAttributes.last_name'>
                  </div>
                </div>
                <div class='field'>
                  <label class='label'>Email</label>
                  <div class='control'>
                    <input class='input' type='text' name='user[email]'
                           v-model.trim='userAttributes.email'>
                  </div>
                </div>
                <div class='field'>
                  <label class='label'>Username</label>
                  <div class='control'>
                    <p class='help'>The name displayed in your reviews and your URL (repaperpress.com/users/{{userAttributes.username}}).</p>
                    <input class='input' type='text' name='user[username]'
                           v-model.trim='userAttributes.username'>
                    <p class='help'><strong>Note:</strong> Changing your URL means you'll need to update bookmarks.</p>
                  </div>
                </div>
                <div class='field'>
                  <label class='label'>Avatar Color</label>
                  <div class='control'>
                    <input class='input' type='text' name='user[color_hex]'
                           v-model.trim='userAttributes.color_hex'
                           v-bind:style="{ color: '#ffffff', backgroundColor: userAttributes.color_hex}">
                    <button class='button is-small'
                            v-on:click='generateRandomColorHex'>Generate Random Color!</button>
                  </div>
                </div>
                <div class='control'>
                  <button class='button is-link' v-on:click='updateUser'>Submit</button>
                </div>
                <Success v-if='userSaved'></Success>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import api from "../api";
import BookCard from "../components/BookCard.vue";
import Errors from "../components/Errors.vue";
import Success from "../components/Success.vue";

export default {
  components: { BookCard, Errors, Success },
  props: ['user', 'purchasedBooks'],
  data() {
    return {
      errors: {},
      userAttributes: {
        color_hex: this.user.color_hex,
        email: this.user.email,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        username: this.user.username
      },
      userSaved: false,
      requestedBooks: {},
      wishlistedBooks: {},
    }
  },
  methods: {
    fetchRequestedBooks: function () {
      api.getUserRequestedBooks(this.user.id).then(response => {
        this.requestedBooks = response.data;
      });
    },
    fetchWishlistedBooks: function () {
      api.getUserWishlistedBooks(this.user.id).then(response => {
        this.wishlistedBooks = response.data;
      });
    },
    generateRandomColorHex: function (e) {
      e.preventDefault();
      // From https://stackoverflow.com/questions/1484506/random-color-generator
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      this.userAttributes.color_hex = color;
    },
    jankyReset: function () {
      this.userSaved = false
    },
    selectTab: function (e) {
      e.preventDefault();
      var selectedTabId = e.target.hash
      $('.active-tab').removeClass('active-tab');
      $(selectedTabId).addClass('active-tab');
    },
    updateUser: function (e) {
      e.preventDefault();
      api
        .updateUser(this.user.id, this.userAttributes)
        .then(response => {
          this.user = response.data;
          this.errors = {};
          this.userSaved = true;
          this.$refs.first_name.focus();
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        });
    },
  },
  created () {
    this.fetchWishlistedBooks()
    this.fetchRequestedBooks()
  }
};
</script>
