<template>
  <div id='review-show' class='media'>
    <div class='media-left' v-bind:style='avatarStyle'></div>
    <div class='media-content'>
      <div class='content'>
        <p>
          <strong>{{ review.title}}</strong>
          <br>
          <a :href='reviewerUrl' target='_blank'><strong>{{ review.user_display_name }}</strong></a> | <a :href='castUrl' target='_blank'>View This Cast</a>
          <br>
          <div>
            <strong>Description:</strong> {{ descriptionText }}
            <hr>
            <span style='white-space: pre-wrap;'>{{displayedContent}}</span>
            <a v-if='truncated' v-on:click='showMore' href='#'>Show More</a>
            <a v-else-if='expanded' v-on:click='showLess' href='#'>Show Less</a>
          </div>
        </p>
      </div>
      <a v-if='signedInUser' v-on:click='upvoteReview' class='button' v-bind:title='tooltipText'>
        <span class='icon'><i class='fas fa-stroopwafel'></i></span>
        <span>Stroopwafel</span>
      </a>
      <a v-else class='button' href='/sign_in' v-bind:title='tooltipText'>
        <span class='icon'><i class='fas fa-stroopwafel'></i></span>
        <span>Stroopwafel</span>
      </a>
      <p class='help'>{{upvoteCountText}}</p>
    </div>
    <div class='media-right'>{{review.created_at}}</div>
  </div>
</template>

<script>
import api from '../api.js'

export default {
  props: ['review', 'userId'],
  data () {
    return {
      displayedContentLength: 350,
      contentLength: this.review.content.length,
      contentPreviewLength: 350,
      tooltipText: "What's a stroopwafel? Delicious. Like a 'like', hat tip, applause, salute, or other such gestures of respect--but tastier with tea.",
      upvotesCount: this.review.upvotes_count
    };
  },
  computed: {
    avatarStyle: function () {
      return {
        backgroundColor: this.review.user_color_hex,
        borderRadius: '5%',
        display: 'inline-block',
        height: '70px',
        width: '70px'
      };
    },
    castUrl: function () {
      return '/books/' + this.review.book_slug + '/casts/' + this.review.cast_id;
    },
    contentIsLong: function () {
      return this.contentLength > this.contentPreviewLength;
    },
    descriptionText: function () {
      var userCastDescription = this.review.user_cast_description;
      var defaultText = 'No description provided for this cast.'
      return userCastDescription === null ? defaultText : userCastDescription;
    },
    displayedContent: function () {
      var content = this.review.content.substr(0, this.displayedContentLength);
      return this.truncated ? content + '...' : content
    },
    expanded: function () {
      return this.contentIsLong && this.displayedContentLength > this.contentPreviewLength;
    },
    reviewerUrl: function () {
      return '/users/' + this.review.user_slug + '/reviews';
    },
    signedInUser () {
      return this.userId != null
    },
    truncated: function () {
      return this.contentIsLong && this.displayedContentLength === this.contentPreviewLength;
    },
    upvoteCountText: function () {
      return this.upvotesCount === 1 ?
        this.upvotesCount + ' person has given this review a stroopwafel' :
        this.upvotesCount + ' people have given this review a stroopwafel'
    }
  },
  methods: {
    showMore: function (e) {
      e.preventDefault();
      this.displayedContentLength = this.contentLength;
    },
    showLess: function (e) {
      e.preventDefault();
      this.displayedContentLength = this.contentPreviewLength;
    },
    upvoteReview: function (e) {
      e.preventDefault();
      api.createUpvote(this.review.id).then(response => {
        this.review.upvote = response.data;
        this.upvotesCount = parseInt(this.review.upvotes_count) + 1;
      });
    }
  }
};
</script>
