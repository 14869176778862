<template>
  <div id='chapters-table'>
    <table class='table is-bordered is-striped is-hoverable is-fullwidth'>
      <tbody>
        <tr>
          <th>ID</th>
          <th></th>
          <th>Ordinal</th>
          <th>CGWs #</th>
          <th>Unassigned CGWs #</th>
          <th>Text Status</th>
          <th>CGWs Status</th>
        </tr>
        <tr v-for='chapter in chapters' v-bind:key='chapter.id'>
          <td>{{chapter.id}}</td>
          <td><button class='button' v-on:click='editChapter(chapter)'>Edit</button></td>
          <td>Chapter {{chapter.ordinal}}</td>
          <td>{{chapter.cgws_count}}</td>
          <td>{{chapter.unassigned_cgws_count}}</td>
          <td v-if="chapter.text_processed == true">Processed</td>
          <td v-else>
            <button v-on:click='processChapterText($event, chapter)' class='button'>Process Text</button>
          </td>
          <td v-if="chapter.cgws_processed == true">
            <a v-bind:href='chapterReviewUrl(chapter)' target='_blank'><button class='button'>Review</button></a>
          </td>
          <td v-else>
            <a v-bind:href='chapterUrl(chapter)' target='_blank'><button class='button'>View</button></a>
          </td>
        </tr>
      </tbody>
    </table>
    <br>
    <chapter-form v-if='showChapterForm'
                  :book-id='bookId'
                  :chapter='chapterForForm'
                  v-on:add-chapter='addChapter'
                  v-on:update-chapter='updateChapter'
                  v-on:hide-form='hideChapterForm'></chapter-form>
    <button v-else class='button is-link'
            v-on:click='showChapterForm = true'>+ Add Chapter</button>
  </div>
</template>

<script>
import api from '../api';
import ChapterForm from '../components/ChapterForm.vue'

export default {
  components: { ChapterForm },
  props: [ 'bookId' ],
  data() {
    return {
      chapters: [],
      chapterForForm: this.newChapterAttributes(),
      showChapterForm: false,
    }
  },
  methods: {
    addChapter: function (newChapter) {
      this.chapters.push(newChapter);
      this.hideChapterForm();
    },
    chapterReviewUrl: function (chapter) {
      return `/books/${this.bookId}/chapters/${chapter.id}/review`;
    },
    chapterUrl: function (chapter) {
      return `/books/${this.bookId}/chapters/${chapter.id}`;
    },
    editChapter: function (chapter) {
      this.chapterForForm = chapter;
      this.showChapterForm = true;
    },
    hideChapterForm: function () {
      this.chapterForForm = this.newChapterAttributes();
      this.showChapterForm = false;
    },
    newChapterAttributes: function () {
      return {
        id: null,
        ordinal: '',
        text: ''
      };
    },
    processChapterText: function (event, chapter) {
      event.target.disabled = true;
      api.processChapterText(chapter.id).then(response => {
        var updatedChapter = response.data
        var chapterIndex = this.chapters.findIndex(function(chapter) {
          return chapter.id === updatedChapter.id;
        });
        this.$set(this.chapters, chapterIndex, updatedChapter)
      });
    },
    updateChapter: function (updatedChapter) {
      var chapterIndex = this.chapters.findIndex(function(chapter) {
        return chapter.id === updatedChapter.id;
      });
      this.$set(this.chapters, chapterIndex, updatedChapter)
      this.chapterForForm = this.newChapterAttributes();
      this.hideChapterForm();
    },
  },
  created() {
    api.getBookChapters(this.bookId).then(response => {
      this.chapters = response.data;
    });
  },
};
</script>
