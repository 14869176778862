<template>
  <div id='gender-word-form'>
    <form>
      <Errors :errors='errors'></Errors>
      <div class='field'>
        <label class='label'>Female</label>
        <div class='control'>
          <input class='input' type='text' name='genderWord[female]'
                 v-model.trim='genderWordAttributes.female'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Male</label>
        <div class='control'>
          <input class='input' type='text' name='genderWord[male]'
                 v-model.trim='genderWordAttributes.male'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Neutral "It"</label>
        <div class='control'>
          <input class='input' type='text' name='genderWord[neutral_it]'
                 v-model.trim='genderWordAttributes.neutral_it'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Neutral "One"</label>
        <div class='control'>
          <input class='input' type='text' name='genderWord[neutral_one]'
                 v-model.trim='genderWordAttributes.neutral_one'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Neutral "Ne"</label>
        <div class='control'>
          <input class='input' type='text' name='genderWord[neutral_ne]'
                 v-model.trim='genderWordAttributes.neutral_ne'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Neutral "They"</label>
        <div class='control'>
          <input class='input' type='text' name='genderWord[neutral_they]'
                 v-model.trim='genderWordAttributes.neutral_they'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Neutral "Ze"</label>
        <div class='control'>
          <input class='input' type='text' name='genderWord[neutral_ze]'
                 v-model.trim='genderWordAttributes.neutral_ze'>
        </div>
      </div>
      <div class='field is-grouped'>
        <div class='control'>
          <button class='button is-link' v-on:click='createOrUpdateGenderWord'>Submit</button>
        </div>
        <div class='control'>
          <button class='button is-text' v-on:click='triggerHideForm'>Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '../api';
import Errors from '../components/Errors.vue'

export default {
  components: { Errors },
  props: ['genderWord'],
  data() {
    return {
      errors: {},
      genderWordAttributes: {
        female: this.genderWord.female,
        male: this.genderWord.male,
        neutral_it: this.genderWord.neutral_it,
        neutral_one: this.genderWord.neutral_one,
        neutral_ne: this.genderWord.neutral_ne,
        neutral_they: this.genderWord.neutral_they,
        neutral_ze: this.genderWord.neutral_ze,
      },
    };
  },
  methods: {
    createGenderWord: function () {
      api.createGenderWord(this.genderWordAttributes)
        .then(response => {
          this.onSuccess(response, 'add-gender-word')
        })
        .catch(error => {
          this.onErrors(error);
        });
    },
    createOrUpdateGenderWord: function (e) {
      e.preventDefault();
      this.genderWord.id == null ? this.createGenderWord() : this.updateGenderWord();
    },
    onErrors: function (error) {
      this.errors = error.response.data.errors;
    },
    onSuccess: function (response, functionName) {
      this.$emit(functionName, response.data)
      this.errors = {};
    },
    triggerHideForm: function (e) {
      e.preventDefault();
      this.$emit('hide-form');
    },
    updateGenderWord: function (e) {
      api.updateGenderWord(this.genderWord.id, this.genderWordAttributes)
        .then(response => {
          this.onSuccess(response, 'update-gender-word')
        })
        .catch(error => {
          this.onErrors(error)
        });
    },
  },
};
</script>
