<template>
  <div id='review-form'>
    <form>
      <Errors :errors='errors'></Errors>
      <div class='field'>
        <label class='label'>Title</label>
        <div class='control'>
          <input class='input' type='text' name='review[title]'
                 v-model.trim='reviewAttributes.title'>
        </div>
      </div>
      <div class='field'>
        <label class='label'>Content</label>
        <p class='help'>What did you love? What did you notice about the writing or your reactions? What didn't read smoothly with this cast?</p>
        <textarea class='textarea' type='text-area' rows='15' name='review[content]'
                 v-model.trim='reviewAttributes.content'></textarea>
      </div>
      <div class='field is-grouped'>
        <div class='control'>
          <button class='button is-link' v-on:click='createOrUpdateReview'>Submit</button>
        </div>
        <div class='control'>
          <button class='button is-text' v-on:click='triggerHideForm'>Cancel</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '../api';
import Errors from '../components/Errors.vue'

export default {
  components: { Errors },
  props: ['cast'],
  data() {
    return {
      errors: {},
    };
  },
  computed: {
    reviewAttributes: function () {
      if (this.cast.review === null) {
        return { title: '', content: '' };
      } else {
        return { title: this.cast.review.title, content: this.cast.review.content };
      }
    }
  },
  methods: {
    createOrUpdateReview: function (e) {
      e.preventDefault();
      this.cast.review === null ? this.createReview() : this.updateReview();
    },
    createReview: function () {
      api.createCastReview(this.cast.id, this.reviewAttributes)
        .then(response => {
          this.onSuccess(response);
        })
        .catch(error => {
          this.onError(error);
        });
    },
    onError: function (error) {
      this.errors = error.response.data.errors;
    },
    onSuccess: function (response) {
      this.cast.review = response.data;
      this.errors = {};
      this.triggerHideForm();
    },
    triggerHideForm: function (e) {
      if (e !== undefined) { e.preventDefault(); }
      this.$emit('hide-form');
    },
    updateReview: function () {
      api.updateCastReview(this.cast.id, this.cast.review.id, this.reviewAttributes)
        .then(response => {
          this.onSuccess(response);
        })
        .catch(error => {
          this.onError(error);
        });
    }
  },
};
</script>
