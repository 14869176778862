<template>
  <div id='books-index' class='has-text-centered'>
    <div v-if='bookScope === "repaperable"'>
      <h1 class='title is-3'>Available Books</h1>
    </div>
    <div v-else class='columns is-centered'>
      <div class='column is-half'>
        <h1 class='title is-3'>Potential Books</h1>
        <p class='subtitle'>Let us know which books we should prioritize making repaperable!</p>
        <p class='help'>
          We can start right away on anything in the public domain. For work still under copyright, your request helps send the message to the copyright holder that there's demand for their stories in a this format.
          <br>
          Don't see the book you want to request? Tweet it @repaperpress.
        </p>
      </div>
    </div>
    <section class='section'>
      <div class='columns is-multiline'>
        <book-card v-for='book in books' v-bind:key='book.id' :book=book :user-id='userId'></book-card>
      </div>
    </section>
  </div>
</template>

<script>
import api from "../api";
import BookCard from "../components/BookCard.vue";

export default {
  components: { BookCard },
  props: ['book-scope', 'user-id'],
  data() {
    return {
      books: {},
    }
  },
  computed: {
    booksParams: function () {
      if (this.bookScope === 'repaperable') {
        return {repaperable: true};
      } else {
        return {non_repaperable: true}
      }
    }
  },
  methods: {
    fetchBooks: function () {
      api.getBooks(this.booksParams).then(response => {
        this.books = response.data;
      });
    },
  },
  created () {
    this.fetchBooks();
  }
};
</script>
