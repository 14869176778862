<template>
  <div id='repapering-demo'>
    <div class='columns container'>
      <div class='column is-one-quarter'>
        <h2 class='title'>How It Works</h2>
        <h3 class='subtitle'>Visualize Repapering</h3>
        <p>
          You choose the characters' pronouns, and behind the scenes Repaper Press generates
          an ebook for you with gendered words that match the pronouns you've selected.
        </p>
        <br>
        <label class='label'>Ratty</label>
        <div class='color-circle ratty-highlight'></div>
        <div class='select'>
          <select v-model='ratty.selected_gender'
                  v-on:change='addHighlight(ratty)'
                  v-on:blur='removeHighlight(ratty)'>
            <option value='female'>Feminine 'She'</option>
            <option value='male'>Masculine 'He'</option>
            <option value='neutral_they'>Neutral 'They'</option>
            <option value='neutral_it'>Neutral 'It'</option>
            <option value='neutral_one'>Neutral 'One'</option>
            <option value='neutral_ne'>Neutral 'Ne'</option>
            <option value='neutral_ze'>Neutral 'Ze'</option>
          </select>
        </div>
        <label class='label'>Moley</label>
        <div class='color-circle moley-highlight'></div>
        <div class='select'>
          <select v-model='moley.selected_gender'
                  v-on:change='addHighlight(moley)'
                  v-on:blur='removeHighlight(moley)'>
            <option value='female'>Feminine 'She'</option>
            <option value='male'>Masculine 'He'</option>
            <option value='neutral_they'>Neutral 'They'</option>
            <option value='neutral_it'>Neutral 'It'</option>
            <option value='neutral_one'>Neutral 'One'</option>
            <option value='neutral_ne'>Neutral 'Ne'</option>
            <option value='neutral_ze'>Neutral 'Ze'</option>
          </select>
        </div>
        <label class='label'>Toad</label>
        <div class='color-circle toad-highlight'></div>
        <div class='select'>
          <select v-model='toad.selected_gender'
                  v-on:change='addHighlight(toad)'
                  v-on:blur='removeHighlight(toad)'>
            <option value='female'>Feminine 'She'</option>
            <option value='male'>Masculine 'He'</option>
            <option value='neutral_they'>Neutral 'They'</option>
            <option value='neutral_it'>Neutral 'It'</option>
            <option value='neutral_one'>Neutral 'One'</option>
            <option value='neutral_ne'>Neutral 'Ne'</option>
            <option value='neutral_ze'>Neutral 'Ze'</option>
          </select>
        </div>
        <label class='label'>Toad's Old Grey Horse</label>
        <div class='color-circle toads-horse-highlight'></div>
        <div class='select'>
          <select v-model='toadsHorse.selected_gender'
                  v-on:change='addHighlight(toadsHorse)'
                  v-on:blur='removeHighlight(toadsHorse)'>
            <option value='female'>Feminine 'She'</option>
            <option value='male'>Masculine 'He'</option>
            <option value='neutral_they'>Neutral 'They'</option>
            <option value='neutral_it'>Neutral 'It'</option>
            <option value='neutral_one'>Neutral 'One'</option>
            <option value='neutral_ne'>Neutral 'Ne'</option>
            <option value='neutral_ze'>Neutral 'Ze'</option>
          </select>
        </div>
        <label class='label'>Group Singular</label>
        <div class='color-circle group-singular-highlight'></div>
        <div class='select'>
          <select v-model='groupSingular.selected_gender'
                  v-on:change='addHighlight(groupSingular)'
                  v-on:blur='removeHighlight(groupSingular)'>
            <option value='female'>Feminine 'She'</option>
            <option value='male'>Masculine 'He'</option>
            <option value='neutral_they'>Neutral 'They'</option>
            <option value='neutral_it'>Neutral 'It'</option>
            <option value='neutral_one'>Neutral 'One'</option>
            <option value='neutral_ne'>Neutral 'Ne'</option>
            <option value='neutral_ze'>Neutral 'Ze'</option>
          </select>
        </div>
      </div>
      <div class='column content is-small'>
        <br>
        <h3>Excerpt from <i>The Wind in the Willows</i> by Kenneth Grahame:</h3>
        <p>"You'll find that nothing whatever has been forgotten, when we make
        our start this afternoon."</p>

        <p>"I beg your pardon," said the Rat slowly, as
        <span class='highlight ratty'>{{subjectiveWord(ratty)}}</span> chewed a straw,
        "but did I overhear you say something about '<i>we</i>,' and '<i>start</i>,' and '<i>this
        afternoon</i>'?"</p>

        <p>"Now, you dear good old Ratty," said Toad
        imploringly, "don't begin talking in that stiff
        and sniffy sort of way, because you know you've
        <i>got</i> to come. You surely don't mean to stick to your dull fusty
        old river all your life, and just live in a hole in
        a bank, and <i>boat</i>? I want to show you the
        world! I'm going to make an <i>animal</i> of you,
        my <span class='highlight ratty'>{{friendWord(ratty)}}</span>!"</p>

        <p>"I don't care," said the Rat doggedly. "I'm
        not coming, and that's flat. And I <i>am</i> going to
        stick to my old river, <i>and</i> live in a hole, <i>and</i>
        boat, as I've always done. And what's more,
        Mole's going to stick to me and do as I do,
        aren't you, Mole?"</p>

        <p>"Of course I am," said the Mole, loyally. "I'll
        always stick to you, Rat, and what you say is to
        be&mdash;has got to be. All the same, it sounds as
        if it might have been&mdash;well, rather fun, you
        know!" <span class='highlight moley'>{{subjectiveWord(moley)}}</span>
        added wistfully. Poor Mole! The
        Life Adventurous was so new a thing to <span class='highlight moley'>{{objectiveWord(moley)}}</span>,
        and so thrilling; and this fresh aspect of it was
        so tempting; and <span class='highlight moley'>{{subjectiveWord(moley)}}</span> had fallen in love at first
        sight with the canary-coloured cart and all its
        little fitments.</p>

        <p>The Rat saw what was passing in <span class='highlight moley'>{{possessiveWord(moley)}}</span> mind,
        and wavered. <span class='highlight ratty'>{{capitalize(subjectiveWord(ratty))}}</span>
        hated disappointing people,
        and <span class='highlight ratty'>{{subjectiveWord(ratty)}}</span> was fond of the Mole,
        and would do almost anything to oblige
        <span class='highlight moley'>{{objectiveWord(moley)}}</span>. Toad was watching
        both of them closely.</p>

        <p>"Come along in, and have some lunch,"
        <span class='highlight toad'>{{subjectiveWord(toad)}}</span>
        said, diplomatically, "and we'll talk it over.
        We needn't decide anything in a hurry. Of
        course, <i>I</i> don't really care. I only want to give
        pleasure to you fellows. 'Live for others!'
        That's my motto in life."</p>

        <p>During luncheon&mdash;which was excellent, of
        course, as everything at Toad Hall always was&mdash;the
        Toad simply let <span class='highlight toad'>{{reflexiveWord(toad)}}</span> go.
        Disregarding the Rat, <span class='highlight toad'>{{subjectiveWord(toad)}}</span>
        proceeded to play upon the inexperienced Mole as on a harp. Naturally a
        voluble animal, and always mastered by
        <span class='highlight toad'>{{possessiveWord(toad)}}</span>
        imagination, <span class='highlight toad'>{{subjectiveWord(toad)}}</span>
        painted the prospects of the
        trip and the joys of the open life and the roadside
        in such glowing colours that the Mole
        could hardly sit in <span class='highlight moley'>{{possessiveWord(moley)}}</span>
        chair for excitement. Somehow, it soon seemed taken for granted by
        all three of them that the trip was a settled
        thing; and the Rat, though still unconvinced
        in <span class='highlight ratty'>{{possessiveWord(ratty)}}</span> mind,
        allowed <span class='highlight ratty'>{{possessiveWord(ratty)}}</span>
        good-nature to over-ride <span class='highlight ratty'>{{possessiveWord(ratty)}}</span>
        personal objections.
        <span class='highlight ratty'>{{capitalize(subjectiveWord(ratty))}}</span>
        could not bear to disappoint <span class='highlight ratty'>{{possessiveWord(ratty)}}</span>
        two friends, who were already deep in schemes and anticipations, planning out
        each day's separate occupation for several weeks
        ahead.</p>

        <p>When they were quite ready, the now triumphant
        Toad led <span class='highlight toad'>{{possessiveWord(toad)}}</span>
        companions to the paddock and set them to capture the old grey horse, who,
        without having been consulted, and to
        <span class='highlight toads-horse'>{{possessiveWord(toadsHorse)}}</span> own
        extreme annoyance, had been told off by Toad
        for the dustiest job in this dusty expedition.
        <span class='highlight toads-horse'>{{capitalize(subjectiveWord(toadsHorse))}}</span>
        frankly preferred the paddock,
        and took a deal of catching... At last
        the horse was caught and harnessed, and they
        set off, all talking at once, each animal either
        trudging by the side of the cart or sitting on
        the shaft, as the humour took <span class='highlight group-singular'>
        {{objectiveWord(groupSingular)}}</span>. It was a
        golden afternoon.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // CHARACTERS
      groupSingular: {
        cssClass: 'group-singular',
        selected_gender: 'male',
      },
      moley: {
        cssClass: 'moley',
        selected_gender: 'male',
      },
      ratty: {
        cssClass: 'ratty',
        selected_gender: 'male',
      },
      toad: {
        cssClass: 'toad',
        selected_gender: 'male',
      },
      toadsHorse: {
        cssClass: 'toads-horse',
        selected_gender: 'male',
      },
      // DECLENSIONS
      friend: {
        female: 'girl',
        male: 'boy',
        neutral_they: 'friend',
        neutral_it: 'friend',
        neutral_one: 'friend',
        neutral_ne: 'friend',
        neutral_ze: 'friend',
      },
      objective: {
        female: 'her',
        male: 'him',
        neutral_they: 'them',
        neutral_it: 'it',
        neutral_one: 'one',
        neutral_ne: 'nem',
        neutral_ze: 'zir'
      },
      possessive: {
        female: 'her',
        male: 'his',
        neutral_they: 'their',
        neutral_it: 'its',
        neutral_one: "one's",
        neutral_ne: 'nir',
        neutral_ze: 'zir'
      },
      reflexive: {
        female: 'herself',
        male: 'himelf',
        neutral_they: 'themself',
        neutral_it: 'itself',
        neutral_one: 'oneself',
        neutral_ne: 'nemself',
        neutral_ze: 'zirself'
      },
      subjective: {
        female: 'she',
        male: 'he',
        neutral_they: 'they',
        neutral_it: 'it',
        neutral_one: 'one',
        neutral_ne: 'ne',
        neutral_ze: 'ze',
      },
    }
  },
  methods: {
    capitalize: function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
    addHighlight: function (character) {
      var elements = $('.' + character.cssClass);
      elements.addClass(`${character.cssClass + '-highlight'}`);
    },
    removeHighlight: function (character) {
      var elements = $('.' + character.cssClass);
      elements.removeClass(`${character.cssClass + '-highlight'}`);
    },
    // DECLENSION FUNCTIONS
    friendWord: function (character) {
      return this.friend[character.selected_gender];
    },
    objectiveWord: function (character) {
      return this.objective[character.selected_gender];
    },
    possessiveWord: function (character) {
      return this.possessive[character.selected_gender];
    },
    reflexiveWord: function (character) {
      return this.reflexive[character.selected_gender]
    },
    subjectiveWord: function (character) {
      return this.subjective[character.selected_gender];
    },
  },
};
</script>

<style>
.color-circle {
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  width: 30px;
}
.highlight {
  border-radius: 15%;
  -webkit-transition: background-color 1s linear;
  -moz-transition: background-color 1s linear;
  -o-transition: background-color 1s linear;
  transition: background-color 1s linear;
}
.group-singular-highlight {
  background-color: #F8E419;
  padding-left: 2px;
  padding-right: 2px;
}
.moley-highlight {
  background-color: #F8857F;
  padding-left: 2px;
  padding-right: 2px;
}
.ratty-highlight {
  background-color: #FCA55D;
  padding-left: 2px;
  padding-right: 2px;
}
.toad-highlight {
  background-color: #2ECC40;
  padding-left: 2px;
  padding-right: 2px;
}
.toads-horse-highlight {
  background-color: #39CCCC;
  padding-left: 2px;
  padding-right: 2px;
}
</style>
