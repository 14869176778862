<template>
  <div id='repaper-header'>
    <nav class='navbar' role='navigation' aria-label='main navigation'>
      <div class='navbar-brand'>
        <a class='navbar-item' href='/'><strong>R E P A P E Я</strong></a>
        <a v-on:click='toggleMobileMenu' class='navbar-burger burger' role='button' aria-label='menu' aria-expanded='false' data-target='navbarContent'>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
        </a>
      </div>

      <div id='navbarContent' class='navbar-menu'>
        <div class='navbar-start'>
          <a class='navbar-item' href='/about'>About</a>
          <a class='navbar-item' href='/contributing'>Contribute</a>
          <a class='navbar-item' href='/articles'>Blog</a>
          <div class='navbar-item has-dropdown is-hoverable'>
            <a class='navbar-link'>Books</a>
            <div class='navbar-dropdown'>
              <a class='navbar-item' href='/books'>Available Books</a>
              <a class='navbar-item' href='/books/potentials'>Potential Books</a>
            </div>
          </div>
        </div>

        <div class='navbar-end'>
          <div class='navbar-item'>
            <div v-if='currentUser === false' class='buttons'>
              <a class='button is-primary' href='/sign_up'><strong>Sign Up</strong></a>
              <a class='button is-light' href='/sign_in'>Log In</a>
            </div>
            <div v-else class='buttons'>
              <a v-bind:href='userPath' class='navbar-item'>Account</a>
              <a v-on:click='signOut' class='navbar-item' href='/sign_out'>Sign Out</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import api from '../api.js'

export default {
  props: ['currentUser'],
  computed: {
    userPath: function () {
      return '/users/' + this.currentUser.slug
    },
  },
  methods: {
    signOut: function (e) {
      e.preventDefault();
      api.deleteSession().then(response => {
        location.replace('/');
      });
    },
    toggleMobileMenu: function () {
      $('.navbar-burger').toggleClass('is-active');
      $('.navbar-menu').toggleClass('is-active');
    },
  },
};
</script>
