<template>
  <div id='payments-table'>
    <table class='table is-bordered is-striped is-hoverable is-fullwidth'>
      <tbody>
        <tr>
          <th>ID</th>
          <th>Amount</th>
          <th>User</th>
          <th>Book</th>
          <th>Product</th>
          <th>Date</th>
          <th>Debit Status</th>
          <th>Stripe Charge</th>
        </tr>
        <tr v-for='payment in payments' v-bind:key='payment.id'>
          <td>{{payment.id}}</td>
          <td>{{payment.total_amount}}</td>
          <td>{{payment.user.full_name}}</td>
          <td>{{payment.book.title}}</td>
          <td>{{payment.product}}</td>
          <td>{{payment.created_at}}</td>
          <td>{{payment.debit_status}}</td>
          <td>{{payment.stripe_charge_id}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from '../api';

export default {
  data() {
    return {
      payments: [],
    }
  },
  created() {
    api.getPayments().then(response => {
      this.payments = response.data;
    });
  },
};
</script>
